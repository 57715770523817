<template>
  <div id="jsForm">
    <van-cell-group>
      <van-cell style="padding-top: 17px;padding-bottom: 17px;">
        <template #title>
          <div>
            <span class="custom-title hidden-text"> <span style="color:rgba(35, 171, 114, 1)">{{form.xn +'年' +codeConvertLabel(form.xq,dict.xj_xqgl)}}</span></span>
            <div style="font-weight:bold">{{form.activityName}}</div>
          </div>
        </template>
        <template #label>
          <div class="custom-centent margin6 hidden-text95">
            <van-icon name="points" /> 活动类型: {{form.activityTypeName}}
          </div>
          <div class="custom-centent margin6 hidden-text95">
            <svg-icon icon-class="date" size="15" /> 时间: {{parseTime(form.createTime, "{y}-{m}-{d}")}}
          </div>
          <div class="custom-centent margin6 hidden-text95">
            <van-icon name="user-o" /> 发起人: {{ form.createBy }}
          </div>
          <div class="custom-centent margin6 hidden-text95">
            <van-icon name="manager-o" /> 审核人: {{ form.reviewerName }}
          </div>
        </template>
      </van-cell>
    </van-cell-group>

    <!-- 教室检查打分 -->
    <van-cell-group title="检查打分">
      <van-collapse v-model="activeNames">
        <van-collapse-item v-for="item,index in form.scoreCheckInfos" :key="index" :title="item.perName" :value="item.perCode" :name="index">
          <div> <span class="collapse-title">院系:</span><span class="collapse-text">{{item.collName}}</span></div>
          <van-button style="height:26px" round type="warning" plain hairline @click="dDelete(index,item.id)">删除</van-button>
          <van-button style="height:26px" round type="warning" plain hairline @click="classEdit(index,item.id)">编辑</van-button>
        </van-collapse-item>
      </van-collapse>
      <div class="form-addList-button">
        <van-button type="primary" native-type="button" size="small" @click="classAdd">添加教室打分</van-button>
      </div>
    </van-cell-group>
    <van-popup v-model="show" @close="close" closeable position="bottom" :style="{ height: '80%', backgroundColor:'#F5F5F5' }">
      <div class="wj-title">班级选择</div>
      <div class="van-popup-title-div"><b>{{isFamilyAdd?'新增家庭成员':'编辑家庭成员'}}</b></div>
      <van-form @submit="familySubmit">
        <van-field required v-model="familyForm.name" placeholder="请填写姓名" label="姓名" :rules="[{ required: true, message: '请填写姓名' }]" />
        <van-field required v-model="familyForm.cardNo" placeholder="请填写身份证号" label="身份证号" :rules="[{ required: true, message: '请填写身份证号' },
        {pattern:cardReg, message: '请输入合法的身份证号'}]" />
        <van-field v-model="familyForm.age" placeholder="请填写年龄" label="年龄" required :rules="[{ required: true, message: '请填写姓名' },
        {
          pattern:/^[+]{0,1}(\d+)$/,message: '请输入合法的年龄'
        }]" />
        <van-field v-model="familyForm.relation" placeholder="请填写与本人关系" required label="与本人关系" :rules="[{ required: true, message: '请填写与本人关系' }]" />
        <van-field v-model="familyForm.unitName" placeholder="请填写工作单位" required :rules="[{ required: true, message: '请填写工作单位' }]" label="工作单位" />
        <van-field v-model="familyForm.occupation" placeholder="请填写职业" required :rules="[{ required: true, message: '请填写职业' }]" label="职业" />
        <van-field v-model="familyForm.annualIncome" placeholder="请填写收入" label="收入(元)" :rules="[{
          pattern:/^[+]{0,1}(\d+)$|^$/,message: '请输入合法的收入'
        }]" />
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit" style="font-size:15px">保 存</van-button>
        </div>
      </van-form>
    </van-popup>

  </div>
</template>

<script>
import crudEvaluationActivity from '@/api/modules/evaluation/evaluationActivity'
import { parseTime, codeConvertLabel } from "@/utils";
import commonOption from '@/api/common/common'
import { getApartmentOption } from '@/api/modules/apartment/flat'
import { getRoomOption } from '@/api/modules/apartment/room'

export default {
  name: 'CheckScore',
  dicts: ['evaluation_activity_type', 'xj_nj', 'xj_xqgl', 'xj_shzt'],
  data() {
    return {
      form: {
        fileId: null,
        scoreCheckInfos: [],
        stuChekInfos: [],
        staffCheckInfos: [],
        id: null,
        activityName: null,
        activityType: null,
        activityTypeName: null,
        xn: null,
        xq: null,
        reviewerNo: null,
        reviewerName: null,
        fileId: null,
        approvalStatus: null,
        remark: null,
      },
      activeNames: [],
      show: false,
      search: "",
      dData: [],
      isClassAdd: false,
      classIndex: 0,
    }
  },
  async created() {
    if (this.$route.query && this.$route.query.id) {
      await crudEvaluationActivity.findById(this.$route.query.id).then((res) => {
        this.form = { ...res }
      })
      if (this.form.scoreCheckInfos && this.form.scoreCheckInfos.length > 0) {
        let res = this.form.scoreCheckInfos.map((item) => {
          if (['1', '2', '3', '4'].indexOf(item.checkType) > -1) {
            commonOption.getClassInfoByUnitcodeSmall(item.collNo).then((res) => {
              this.$set(item, 'classInfos', res)
            })
          } else {
            getRoomOption({ floorCode: item.floorCode }).then((res) => {
              this.$set(item, 'roomOptions', res)
            })
          }
          return item;
        })
        this.checkScore = {
          scoreCheckInfos: res,
          zbid: data.id
        }
      } else {
        if (['1', '2', '3', '4'].indexOf(this.form.checkType) > -1) {
          // 班级打分
          this.classShow = true
          this.roomShow = false
        } else {
          // 宿舍打分
          this.classShow = false
          this.roomShow = true
        }
      }
    }
  },
  methods: {
    codeConvertLabel, parseTime,
    classAdd() {
      this.show = true;
      this.isClassAdd = true
    },
    classEdit() {
      this.show = true;
      this.isClassAdd = false
    },
    dDelete() {
      this.form.scoreCheckInfos.splice(index, 1);
    },
    close() {
      this.teacherInfoShow = false;
      this.dData = []
      this.search = ""
    },
    onSearchHandle(queryString) {
      getStaffInfosSmallNoPage({ staffNo: queryString })
        .then((staffList) => {
          if (staffList && staffList.length) {
            this.dData = staffList
          } else {
            this.dData = []
          }
        })
    },
  },

}
</script>

<style lang="scss" scoped>
#jsForm {
  .form {
    ::v-deep.radio-other-info {
      .van-cell {
        .van-field__label {
          width: 11.2em;
        }
      }
    }

    .van-field__label {
      width: 110px;
    }
    .van-cell {
      font-size: 15px;
      line-height: 32px;
      height: 50px;
    }
    .textarea-style {
      height: 100%;
    }
  }

  .wj-title {
    text-align: center;
    line-height: 55px;
    font-size: 17px;
    font-weight: bold;
    height: 40px;
    background-color: #fff;
  }
  .wj-search {
  }
  .stu-card {
    margin-top: 18px;
  }
  .wj-body {
    overflow-y: scroll;
    height: calc(80vh - 100px);
    padding: 0 18px;
  }
}
</style>