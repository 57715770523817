import request from '@/utils/request'
import qs from 'qs'

export function getRoom(params) {
    return request({
        url: 'api/room',
        method: 'get',
        params
    })
}


export function getRoomOption(params) {
    return request({
        url: 'api/room/getRoomOption',
        method: 'get',
        params
    })
}

export function getRoomOptionAuth(params) {
    return request({
        url: 'api/room/getRoomOptionAuth',
        method: 'get',
        params
    })
}



export function add(data) {
    return request({
        url: 'api/room',
        method: 'post',
        data
    })
}

export function del(ids) {
    return request({
        url: 'api/room',
        method: 'delete',
        data: ids
    })
}

export function edit(data) {
    return request({
        url: 'api/room',
        method: 'put',
        data
    })
}

export function allocationRoom(data) {
    return request({
        url: 'api/room/setting',
        method: 'post',
        data
    })
}


export function download(params) {
    return request({
        url: 'api/room/download' + '?' + qs.stringify(params, { indices: false }),
        method: 'get',
        responseType: 'blob'
    })
}

export default { del, edit, add, getRoom, download, getRoomOption ,allocationRoom,getRoomOptionAuth}